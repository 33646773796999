<template>
    <div class="bgw sh pf cb l m_h side-menu" id="Side-Menu" style="top:0px; bottom:0px; width:250px; z-index:1000;" :style="`${store.state.locales['dir_left']}`+':0px'">
        <div class="c ph50">
            <div class="sq40 mt10 con" style="background-image:url('https://trilivery.com/TrileveryLogo.png')"></div>
            <div class="ov10 la ba bgb20  round h m_sb"><span class="mdi mdi-close pointer" @click="handleOpenSideMenu"></span></div>
        </div>

        <div class="mt20 ph20">
            <div class="upper ph10 cb50 fs12 mb10 b">{{store.state.locales['main_menu1']}}</div>

            <!-- <a href="/dashboard"> -->
            <router-link  :to="'/dashboard/'+`${nodes[1]}`">
            <div class="mt5 dashboard_btn pointer p10 fs16 cb60 round2x" n0>
                <div class="ov10 ra" style="width:20px">
                    <span class="fs20 cc mdi mdi-speedometer"></span>
                </div>
                <div class="lh20 vm pl30 pr10 oh fs14">{{store.state.locales['dashboard']}}</div>
            </div>
            </router-link>
            <!-- </a> -->
            <!-- <a href="/credit"> -->
            <router-link :to="'/credit/'+`${nodes[1]}`">
                <div  class="mt5 credit_btn pointer p10 fs16 cb60 round2x" n0>
                    <div class="ov10 ra" style="width:20px">
                        <span class="fs20 cc mdi mdi-credit-card"></span>
                    </div>
                    <div class="lh20 vm pl30 pr10 oh fs14">{{store.state.locales['credit']}}</div>
                </div>
            </router-link>
            <!-- </a> -->
            <div class="ph10 cb50 b mb10 fs12 mt30 upper">{{store.state.locales['settings']}}</div>
            <!-- <a href="/settings/profile"> -->
            <router-link  :to="'/settings/'+`${nodes[1]}`+'/profile'">
                <div  class="mt5 profile_btn p10 fs16 cb60 round2x" n2>
                <div class="ov10 ra" style="width:20px">
                    <span class="fs20 cc mdi mdi-account"></span>
                </div>
                <div class="lh20 vm pl30 pr10 oh fs14">{{store.state.locales['profile']}}</div>
                </div>
            </router-link>
            <!-- </a> -->

            <!-- <a href="/settings/general"> -->
            <router-link :to="'/settings/'+`${nodes[1]}`+'/general'">
            <div  class="mt5 general_btn pointer p10 fs16 cb60 round2x" n2>
                <div class="ov10 ra" style="width:20px">
                    <span class="fs20 cc mdi mdi-cog"></span>
                </div>
                <div class="lh20 vm pl30 pr10 oh fs14">{{store.state.locales['general']}}</div>
            </div>
            </router-link>
            <!-- </a> -->
        </div>
    </div>
</template>

<script>
import imageurl1 from "@/assets/logo.png"
import { onMounted, ref} from "vue";
import store from '@/store'
// import router from "@/router";
import { useRoute } from "vue-router";

export default {

    watch: {
    $route(to, from) {
        this.nodes = to.fullPath
          .replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");
          let menu=document.getElementById("Side-Menu");
           menu.classList.toggle("h");

    },
  },
    
    setup() {
    const route = useRoute();
    let imageurl =ref(imageurl1);
    let nodes=ref([])

        onMounted(() => {
         nodes.value = route.fullPath
          .replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");

        });
        


    function handleOpenSideMenu(){
        let menu=document.getElementById("Side-Menu");
        if(menu.classList.contains("m_h")){
            menu.classList.remove("m_h")
        }
        else{
            menu.classList.toggle("h");
        }
    }
    return {
        imageurl,
        handleOpenSideMenu,
        store,
        nodes,

        };
    },
}
</script>
<style scoped>
[n0]:hover,[n2]:hover{
    background-color: #f5faff;
    color:#000000;
}
[n0]:hover>.ov10,[n2]:hover>.ov10{
    color: #F48F00;
}
[n0].active>.ov10,[n2].active>.ov10{
    color: #F48F00;
}
[n0].active,[n2].active{
    background-color: #E7F3FF;
    color:#000000;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .side-menu {
        display: block;
    }
}

</style>