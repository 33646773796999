<template>
    <section>
        <div class="mt20 fs24 b">General</div>
        <div class="mt20 bgw round2x p20">
            <div class="fs16 b l">{{store.state.locales['publish']}}</div>
            <div class="mt5 fs14">{{store.state.locales['publish_description']}}</div>
            <!-- <div class="g_nm"> -->
            <label class="switch mt8">
                <input type="checkbox" id="toggle" @change="goSwitch" >
                <span class="slider roundtoggle"></span>
            </label>
            <!-- </div> -->
        </div>

    </section>
</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #37BA31;
}

input:focus + .slider {
  box-shadow: 0 0 1px #37BA31;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.roundtoggle {
  border-radius: 34px;
}

.slider.roundtoggle:before {
  border-radius: 50%;
}
</style>



<script>
import store from '@/store'
import {ref,onMounted} from "vue"
import { useRoute } from "vue-router";
export default {
    setup() {
      let active=ref("")
      let val=ref("")
      let nodes=ref([])
      const route = useRoute();

      onMounted(() => {
         nodes.value = route.fullPath
          .replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");
        if(store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].Active==1){
          document.getElementById("toggle").checked = true;

        }else{
          document.getElementById("toggle").checked = false;

        }

    });

        function goSwitch(){
        if(document.getElementById("toggle").checked){
            active.value=1
        }else { 
            active.value=0
        }
        store.methods.putData('Providers/',store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].objectId,{
                "Active":active.value,
            },function(response,success){
            if(success){
              store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].Active=active.value
              let x=window.btoa(JSON.stringify(store.state.globalData.user))
              store.methods.BSC("TRICLED",x)
              console.log(store.state.globalData)
                }else{
                    alert("Somethings goes wrong please try again")
                }
            }) 
    }
    return {
        goSwitch,
        active,
        val,
        store
        };
    },
}
</script>
