import { createI18n } from "vue3-i18n";

const messages = {
  en: {
    menu: ["Home"],
    test: "test",
  },
};

const i18n = createI18n({
  locale: "en",
  messages: messages,
});

export default i18n;