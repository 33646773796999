import { createRouter, createWebHistory } from "vue-router";
// import Login from "../views/Login.vue";
import Main from "../views/dashboard/Main.vue";
// import store from "@/store";
// import App from "../App.vue";


const routes = [
  // {
  //   path: "/",
  //   name: "App",
  //   component: App,
  // },
  {
    path: "/:n1?/:n2?/:n3?",
    name: "Main",
    props: true,
    component: Main,
    //to stop anyone to go to dashboard page//
    // meta: {
    //   requiresAuth: true,
    // },
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

//function before enter the router what will happen//
// router.beforeEach((to, from, next) => {
//   if (!to.meta.requiresAuth) {
//     if (Object.keys(store.state.globalData.user).length !== 0) {
 

//       next({ name: 'Main', params: { n1: 'dashboard' } });
//     } else next();
//     // if(Object.keys(store.state.globalData.user).length === 0){
//     //   next({path: '/',});
//     // }else{
//     //   next();
//     // }
//   } else {
//     // next()
//     if (Object.keys(store.state.globalData.user).length === 0) {
//       next();
//     }else{
//       next({ name: 'Main', params: { n1: 'dashboard' } })
//     }
//   }
// });

export default router;
