<template>
    <div class="pt50 ph25">
        <component v-bind:is="childComp"></component>
    </div>
</template>
<script>
import Profile from "@/views/dashboard/_settings/Profile.vue";
import General from "@/views/dashboard/_settings/General.vue";
import EditProfile from "@/components/EditProfile.vue"
import store from "@/store";
import {ref,onMounted} from 'vue'
import { useRoute } from "vue-router";
import router from "@/router";

export default {
    components: {
        Profile,
        General,
        EditProfile
    },
    watch:{
    $route (to, from){
        let nodes=to.fullPath.replace(new RegExp("^[" + '/' + "]+"), "").split("/")
        this.childComp=nodes[2]
        if(nodes[3]!=undefined) this.childComp='EditProfile'

      store.methods.setGlobalListeners(router)
    }
} ,
    setup() {
        const route = useRoute();
        let childComp=ref("profile")

    onMounted(() => {
      let nodes=route.fullPath.replace(new RegExp("^[" + '/' + "]+"), "").split("/")
      childComp.value=nodes[2]
      if(nodes[3]!=undefined) childComp.value='EditProfile'

    store.methods.setGlobalListeners(router)
     
    });

        return{
            store,
            childComp
        }
        
    },
}
</script>
