<template>
  <div id="nav" >
    <component v-bind:is="kuku"></component>
  </div>
</template>

<script>
import { provide } from "vue";
import store from "@/store";
import { onMounted, ref ,onBeforeMount} from "vue";
import { useRoute } from "vue-router";
import Login from "./views/Login.vue";
import Main from "./views/dashboard/Main.vue";
import router from "@/router";

export default {
  components: {
    Login,
    Main,
  },

  watch: {
    $route(to, from) {
      let nodes = to.fullPath.replace(new RegExp("^[" + "/" + "]+"), "").split("/");
      if(nodes[0]==='logout'){
        store.methods.logout()
        parent.location.href='/';      }
    store.methods.setGlobalListeners(router)
    },
  },

  setup() {
    const route = useRoute();
    let kuku = ref("");
    provide("store", store);
    onBeforeMount(() => {
  
      store.methods.getData(
            {
                "fields":"Key,En,Ar",
                "limit":-1
            },
            '_Locale?',function(response,success){
                if(success){
                  let a=[]
                  if (store.state.globalData.settings.language === "en") {
                    a = Object.assign({}, ...response.results.map((x) => ({ [x.Key]: x.En })));
                  } else {
                    a = Object.assign({}, ...response.results.map((x) => ({ [x.Key]: x.Ar })));
                  }
                  store.state.locales=a
                  console.log(a)
                }else{
                        alert("Something goes wrong plz try again")
                }
            })
    }
    )

    onMounted(() => {
      if (store.methods.BGC("LOCAL") != undefined) {
        store.state.globalData.settings.language=store.methods.BGC("LOCAL")
      }
      let x= document.getElementsByTagName("html");
      x[0].setAttribute("lang", store.state.globalData.settings.language);
      store.methods.setGlobalListeners(router)
      if (store.methods.BGC("TRICLED") != undefined) {
        let x = window.atob(store.methods.BGC("TRICLED"));
        store.methods.setData("user", JSON.parse(x));
        // console.log("rrrr",route)
        kuku.value = "Main";
        // 
        
      } else {
        kuku.value = "Login";
      }
      let nodes = route.fullPath.replace(new RegExp("^[" + "/" + "]+"), "").split("/");
      if(nodes[0]==='logout'){
        store.methods.logout()
        parent.location.href='/';    

      }
      if(store.state.globalData.settings.language==='ar'){
        let head  = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');
        // set the attributes for link element 
        link.rel = 'stylesheet'; 
        link.type = 'text/css';
        link.href = 'https://s.beacdn.com/c/c_ar.css?v=1.0.0.484'; 
        head.appendChild(link);
        }

    });
    return {
      route,
      kuku,
      store,
    };
  },
};
</script>
<style >
/* .pl270 {
  padding-left: 250px;
      } */
      @media screen and (max-width: 768px) {
        .pl250 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
  .normal{
      box-shadow: none;
      background-color: transparent; 
    }
    .normal a {
      color:#000000 ;
    }
    .normal span{
      color:#000000 ;
    }
  .dark{
      box-shadow: none;
      background-color: rgba(0,0,0,0) ; 
      color:white ;
    }
    .dark a {
      color:white ;
    }
    .dark span {
      color:white ;
    }
    
    .light{
      box-shadow: none;
      background-color: #FFFFFF; 
      color:#000000 ;
      box-shadow:0 1px 2px rgb(0 0 0 / 20%);
    }
    .light a {
      color:#000000 ;
    }
    .light span{
      color:#000000 ;
    }



#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
