import { reactive } from "vue";
import axios from "axios";

const state = reactive({
  globalData: {
    user: {},
    settings: {
      language: "en",
    },
    componentName: "",
    // componentName2:'',
    formsComptName: "",
    callPopup: "",
  },
  locales:[]
});

const methods = {

  logout(){
    document.cookie = "TRICLED=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.setData('user',{})
  },
  //set state global data//
  setData(key, value) {
    if (state.globalData.hasOwnProperty(key)) {
      state.globalData[key] = value;
    } else {
      state.globalData[key] = value;
    }
  },

   setGlobalListeners(router) {
  // console.log(router)
    // var d = document.querySelectorAll("a");
    // d.forEach((element) => {
    //   // console.log(element);
    //   element.addEventListener("click", function (e) {
    //     e.preventDefault();
    //     router.push(this.getAttribute("href"));
    //   });
    // });
    
  },

  updateNodes(nodes) {
    let allnodes = document.querySelectorAll("[n0],[n1],[n2]");
    allnodes.forEach((el) => {
      el.classList.remove("active");
    });

    let nodes0 = document.querySelectorAll("." + nodes[0] + "_btn");
    nodes0.forEach((el) => {
      el.classList.add("active");
    });
    let nodes1 = document.querySelectorAll("." + nodes[1] + "_btn");
    nodes1.forEach((el) => {
      el.classList.add("active");
    });
    let nodes2 = document.querySelectorAll("." + nodes[2] + "_btn");
    nodes2.forEach((el) => {
      el.classList.add("active");
    });
  },

  //function get data//
  getData(obj, className, callback) {
    let getPairs = (obj, keys = []) =>
      Object.entries(obj).reduce((pairs, [key, value]) => {
        if (typeof value === "object")
          pairs.push(...getPairs(value, [...keys, key]));
        else pairs.push([[...keys, key], value]);
        return pairs;
      }, []);
    let x = getPairs(obj)
      .map(
        ([[key0, ...keysRest], value]) =>
          `${key0}${keysRest.map((a) => `[${a}]`).join("")}=${value}`
      )
      .join("&");
    axios.get(className + x).then((response) => {
      let respo = response.data;
      if (Object.prototype.hasOwnProperty.call(respo, "error")) {
        callback(respo, false);
      } else {
        callback(respo, true);
      }
    });
  },

  //function post data//
  postData(className, arrayFields, callback) {
    axios.post(className, arrayFields).then((response) => {
      let respo = response.data;
      if (this.hasError(respo)) {
        callback(respo, false);
      } else {
        callback(respo, true);
      }
    });
  },

  hasError(obj) {
    return Object.prototype.hasOwnProperty.call(obj, "error");
  },

  //function put data//
  putData(className, id, arrayFields, callback) {
    axios.put(className + id, arrayFields).then((response) => {
      let respo = response.data;
      if (Object.prototype.hasOwnProperty.call(respo, "error")) {
        callback(respo, false);
      } else {
        callback(respo, true);
      }
    });
  },

  //get cookie
  BGC(n) {
    let c = "; " + document.cookie;
    let p = c.split("; " + n + "=");
    if (p.length == 2)
      return p
        .pop()
        .split(";")
        .shift();
  },

  //set cookie
  BSC(n, v) {
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + 24 * 60 * 60 * 1000 * 365;
    now.setTime(expireTime);
    //   var hst = window.location.hostname;
    document.cookie =
      n + "=" + v + "; expires=" + now.toGMTString() + "; path=/;";
    // domain=" + "http://localhost:8080/";
  },
  // function to edit the classes in an html tag
  editClasses(className, action, value){
    let x = document.querySelectorAll(className)
    x.forEach(element => {
        if(action == 'add'){
          element.classList.add(value)
        }else if(action == 'remove'){
          element.classList.remove(value)
        }else if(action == 'toggle'){
          element.classList.toggle(value)
        }
    });
  }
};

export default {
  // state: readonly(state),
  state,
  methods,
};
