<template>
    <div class="home c">
        <tbl class="ov">
            
            <cl class="hv100 ph10 pv10 w50 m_h c vt" style="background-color:#E8ECFF" >
                <div class="ph20 l fs40 mt20 b fh" style="color:#197FAF; z-index:2;">Welcome to us</div>
                <div class="p10 fs0 ov la ba r" style="z-index:1px;">
                    <div class="mt10">
                            <div class="g_nm fs0 pv4">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml30">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml30">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml8">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml30">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml30">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml8">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml30">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                            <div class="g_nm fs0 pv4 ml30">
                                <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                            </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                    <div class="mt10">
                        <div class="g_nm fs0 pv4">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml8">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                        <div class="g_nm fs0 pv4 ml30">
                            <div class="round100 bgb30" style="width:4px; height:4px;"></div>
                        </div>
                    </div>
                </div>
                <div class="p10 fs0 ov ra ta  w50 md_w60 l" style="z-index:1px;">
                <div class="">
                    <div class="g_nm fs0 pv4">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                </div>
                <div class="mt10">
                    <div class="g_nm fs0 pv4">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                </div>
                <div class="mt10">
                    <div class="g_nm fs0 pv4">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                </div>
                <div class="mt10">
                    <div class="g_nm fs0 pv4">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                </div>
                <div class="mt10">
                    <div class="g_nm fs0 pv4">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                </div>
                <div class="mt10">
                    <div class="g_nm fs0 pv4">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml8">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                    <div class="g_nm fs0 pv4 ml30">
                        <div class="round100 bgb20" style="width:4px; height:4px;"></div>
                    </div>
                </div>
                </div>
                <div class="ov20 con" style="z-index:3px; background-position:center 70%;" :style="{backgroundImage:`url(${image})`}" ></div>
            </cl>
            <cl class="vm pv20 w50 m_pv50" >
                <div class="flexi c">
                    <div class=" g_nm w40 sq10 con" style="background-image:url('https://trilivery.com/TrileveryLogo.png')"></div>
                        <div class="g_nm w90"></div>
                    <div class="g l w80">
                        <!-- this contain the login -->
                        <div class="mt20 fs30 md_fs26 m_fs20 m_m_fs16 b l">Hello! Welcome Back.</div>
                        <form @submit.prevent="login">
                            <div class="mt30 b cb70">Email Address </div>
                            <input v-model='email' class="mt10  p10 lh26 w100 bg-input sh" type="email" placeholder="Example@mail.com">
                            <div class="fs0 b cb70 mt30">
                                <div class="g_nm fs14 w40" style="min-widt:65px;">Password</div>
                                <div class="g_nm fs14 w60 r"> <a href="">Forget Password</a> </div>
                            </div>
                            <div class="mt10">
                                <input v-model='password' class=" p10 lh26 w100 bg-input sh" type="password" id="password" placeholder="Enter assword"/>
                                <div class="btn pointer fs18 ov la " style="right:20px"><span class="cc mdi mdi-eye-off-outline"   @click="switchVisibility"></span></div>
                            </div>
                            <div type="submit" class="mt30 ">
                                <button type="submit" class="bg-oil b0 brdr w100 pointer pv10 fs18 start cw c">Get Started</button>
                            </div>
                        </form>    
                    </div>
                </div>
            </cl>
        </tbl>
    </div>
</template>

<script>
import imageurl from '../assets/image2.svg';
import {ref}  from 'vue';
import router from "@/router";
import axios from "axios";
import store from '@/store'
export default {
    name: 'Home',
    setup(){
        let image=ref(imageurl)
        let email=ref("")
        let password=ref("")


        function login(){
        store.methods.getData(
            {
                "fields":"Email,firstName,lastName,Mobile,Balence",
                 "where": {
                    "Email": email.value,
                    "Password": password.value,
            }
            },
            'Users?',function(response,success){
                if(success){
                    if (response.results.length == 0) {
                        alert("Incorrect Password Or Email");
                    } else {
                        store.methods.setData('user',response.results)
                        getPrivileges(store.state.globalData.user[0].objectId)
                   }
                }else{
                        alert("Something goes wrong plz try again")
                }
            })

        }

        function getPrivileges(userId){
        store.methods.getData(
            {"include":
            [
            {
                "className":"Providers","field":"Provider","fields":"Name,Balance,Active,Type"
            }
            ],
            "fields":"Type",
             "where": {
                "User":userId
            }   
            },
            'Privileges?',function(response,success){
                if(success){
                        let a = Object.assign({}, ...response.results.map((x) => ({ [x.objectId]: x })))
                        store.state.globalData.user[0].privileges=a
                        store.state.globalData.user[0].privilegeZero=response.results[0].objectId
                        let x=window.btoa(JSON.stringify(store.state.globalData.user))
                        store.methods.BSC("TRICLED",x)
                        console.log("login ",store.state.globalData.user)
                        location.reload();
                   
                }else{
                        alert("Something goes wrong plz try again")
                }
            })
        }

        function LoginWithgfb(a){
        axios.post('https://auth.backendadmin.com/'+a+'/orQoU7EFT9o_mF2e0N0aoV8ga2HA-fEvrnHpO9hsnqg')
            .then(response => { 
                console.log("login",a) 
                console.log(response) 
            })
            .catch( err => {
                console.log("error is: ",err)
            })
            
    
        }

        function switchVisibility() {
            let passwordField = document.querySelector("#password")
            let eye = document.querySelector(".mdi")
            console.log(eye);
            if (passwordField.getAttribute('type') === 'password'){
                passwordField.setAttribute('type', 'text')
                eye.classList.remove("mdi-eye-off-outline")
                eye.classList.add("mdi-eye-outline")
            } 
            else {
                passwordField.setAttribute('type', 'password')
                eye.classList.add("mdi-eye-off-outline")
                eye.classList.remove("mdi-eye-outline")
            }
        }
        return{
            image,
            switchVisibility,
            login,
            email,
            password,
            LoginWithgfb
        };
    },
}
</script>

<style >

.bg-input{
    background-color: #d6b9b51c;
    border: 1px solid rgba(0,0,0,0);
}
.bg-input:hover,:focus{
    border:1px solid #C94A38 ;
}
.start{
    background-color: #C94A38;
}
.start:hover{
    background-color: #c93a27;

}
.login:hover{
    background-color: #0041da;
}
a{
    color:rgb(122, 122, 206);
}
a:hover{
    color:#2e28e0;
    }
.bg-blue{
    background-color: #2464F6;
    }
.c-blue{
    color: #2464F6;
    }
</style>