<template>
<div>
    <div class="mt20 fs24 b">{{store.state.locales['edit_ptofile']}}</div>
    <form action="#" @submit.prevent="saveChanges" >
        <div class="bgw w50 m_w100 g_nm fs0 round2x p15 mt20">
            <div class="g">
                <tbl>
                    <cl class="w50">
                        <div class="b cb60 fs14">{{store.state.locales['firstname']}}</div>
                        <div class="mt8">
                            <input  v-model="fName" class="round2x brdr lh34 fs14 ph10 w100" type="text" placeholder="First Name" id="input-FirstName"  required>
                        </div>
                    </cl>
                    <cl class="colSep20" style="height:20px;"></cl>
                    <cl class="w50">
                        <div class="b cb60 fs14">{{store.state.locales['lastname']}}</div>
                        <div class="mt8">
                            <input v-model="lName" class="round2x brdr lh34 fs14 ph10 w100" type="text" placeholder="Last Name" id="input-LastName"   required>
                        </div>
                    </cl>
                </tbl>
            </div>
            <br>
            <div class="">
                <div class="mt20 b cb60 fs14">{{store.state.locales['email']}}</div>
                <div class="mt8 g_nm w100 m_m_w100">
                    <input v-model="email" class="round2x brdr lh34 fs14 ph10 w100" type="email" placeholder="Enter Your email please" id="input-email"  required>
                    </div>
                <div class="mt20 b cb60 fs14">{{store.state.locales['mobile']}}</div>
                <div class="mt8 g_nm w100 m_m_w100">
                    <input v-model="mobile" class="round2x brdr lh34 fs14 ph10 w100" type="text" placeholder="Enter Your mobile number please" id="input-email"  required>
                </div>
                <div class="mt20 c">
                    <button class="g_nm  m_w60 btn b0 pointer round2x save cw b ph30 pv10 m_ph50" type="submit">
                        {{store.state.locales['save']}}
                    </button>
                    <router-link :to="'/settings/'+`${nodes[1]}`+'/profile'"><div class="g_nm m_mt10 m_w60 btn b0 pointer round2x bg-orange-tri cw b ph30 pv10 m_ph50 fs14 ml10 m_ml0">{{store.state.locales['cancel']}}</div></router-link>
                </div>
            </div>
        </div>
    </form>
</div>
</template>
<script>
import store from '@/store'
import {ref,onMounted} from 'vue'
import router from "@/router";
import { useRoute } from "vue-router";
export default {
    setup() {
        let nodes=ref([])
        const route = useRoute();
        let fName=ref(store.state.globalData.user[0].firstName)
        let lName=ref(store.state.globalData.user[0].lastName)
        let email=ref(store.state.globalData.user[0].Email)
        let mobile=ref(store.state.globalData.user[0].Mobile)

        onMounted(() => {
            nodes.value = route.fullPath
          .replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");
        })


    
        function saveChanges(){
            store.methods.putData('Users/',store.state.globalData.user[0].objectId,{
                "firstName":fName.value,
                "lastName":lName.value,
                "Email":email.value,
                "Mobile":mobile.value
            },function(response,success){
            if(success){
                store.methods.setData('user',[{"objectId":store.state.globalData.user[0].objectId,"firstName":fName.value, "Email":email.value,"lastName":lName.value,"Mobile":mobile.value}])
                let x=window.btoa(JSON.stringify(store.state.globalData.user))
                store.methods.BSC("TRICLED",x)
                router.push({ name: 'Main', params: { n1: 'settings',n2:nodes.value[1],n3:'/profile' } })
                }else{
                    alert("Somethings goes wrong please try again")
                }
            })  
         
        }
        return{
            saveChanges,
            fName,
            lName,
            email,
            store,
            mobile,
            nodes

        }
    },
}
</script>
<style scoped>
.save{
    background-color:#197FAF;
}
.save:hover{
    background-color:#156a92;
}
.bg-orange-tri{
    background-color: #F48F00;
}
.bg-orange-tri:hover{
    background-color: #dd8100;
}




input{
    border-color:#bbdbfa;
    background-color:#f5faff;
}
input:focus,input:hover{
    border-color:#197FAF;
    background-color:#E7F3FF;
}
</style>
