<template>
    <section>
        <div class="mt20 fs24 b">{{store.state.locales['profile']}}</div>
        <div class="pointer g_nm">
            <!-- <a href="/settings/profile/edit"><span class=" edit mdi mdi-square-edit-outline mr5"></span><div class="fs14 g_nm fs14 b">Update you Information</div></a> -->
        <router-link class="edit"  :to="'/settings/'+`${nodes[1]}`+'/profile/edit'"><span class=" edit mdi mdi-square-edit-outline mr5"></span><div class="fs14 g_nm b">{{store.state.locales['']}}Update you Information</div></router-link>
            
        </div>
        <div class="p20 mt20 fs14 round4x sh bgw" >
            <div class="information cap" id="information" v-for="user in store.state.globalData.user" v-bind:key="user" >
                <div v-if="!isVisible">
                <div class="mt10">
                    <div class="mr5 g_nm">{{store.state.locales['firstname']}}: </div>
                    <div id="first-name" class="b g_nm">{{user.firstName}}</div>
                </div>
                <div class="mt10">
                    <div class="mr5 g_nm">{{store.state.locales['lastname']}}: </div>
                    <div id="last-name" class="b g_nm">{{user.lastName}}</div>
                </div>
                <div class="mt10">
                    <div class="mr5 g_nm">{{store.state.locales['email']}}: </div>
                    <div id="email" class="b g_nm">{{user.Email}}</div>
                </div>
                <div v-if="user.Mobile!=''" class="mt10">
                    <div class="mr5 g_nm">{{store.state.locales['mobile']}}: </div>
                    <div id="phone" class="b g_nm">{{user.Mobile}}</div>
                </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import store from '@/store'
import router from "@/router";
import { onMounted,ref } from '@vue/runtime-core';
import { useRoute } from "vue-router";
export default{
        watch:{
    $route (to, from){
        store.methods.setGlobalListeners(router)
    }
} ,
    setup() {
        const route = useRoute();
        let nodes=ref([])

    onMounted(() => {
      store.methods.setGlobalListeners(router)
               nodes.value = route.fullPath
          .replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");

     
    });
        function editProfile(){
            //  router.push({ name: 'Main', params: {n2:'edit' } })
           store.state.globalData.settings.selectComp="editProfile"
        }
        return{
            store,
            editProfile,
            nodes
        }
        
    },
}
</script>
<style scoped>
.edit:hover>div{
    text-decoration: underline;
    color:#197FAF;
}
</style>
