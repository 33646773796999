<template>
    <div class="pt50 ph25">
        {{store.state.locales['']}}
        <div class="mt20 fs24 b">{{store.state.locales['credit']}}</div>
        <div class="mt10">
            <div class="g_nm fs34">${{store.state.globalData.user[0].Balence}}
                <div class="cb50 fs18">{{store.state.locales['credit_balance']}}</div>
            </div>
            <div class="g_nm vt m_w100 m_mt20 {{store.state.locales['dir_right']}} m_none fh">
                <div class="sbtn m_m_w100 pointer bg-blue-tri cw ph10 pv4 round2x" @click="handleAddMoney">{{store.state.locales['add_money']}}</div>
            </div>
        </div>
        <!-- transactions options -->
        <div class="bgw mt20 round2x p15">
            <span class="g_nm mr15 m_m_pv5 m_m_w100 m_m_c">
                <label>
                    <input class="h" type="radio" name="withdraw-type" value="allTrans">
                    <div class="allTrans methods pointer bg-blue-tri cw ph10 pv4 round2x active"  id="allTrans" @click="activeMethod('allTrans')">
                        <div class="fs14 p5 oh" @click="getAllTransactions">{{store.state.locales['all_transaction']}}</div>
                    </div>
                </label>
            </span>
            <span class="g_nm mr15 m_m_pv5 m_m_w100 m_m_c">
                <label>
                    <input class="h" type="radio" name="withdraw-type" value="inTrans">
                    <div class="inTrans methods pointer bg-blue-tri cw ph10 pv4 round2x"  id="inTrans" @click="activeMethod('inTrans')">
                        <div class="fs14 p5 oh" @click="getIncomeTransactions">{{store.state.locales['income']}}</div>
                    </div>
                </label>
            </span>
            <span class="g_nm mr15 m_m_pv5 m_m_w100 m_m_c">
                <label>
                    <input class="h" type="radio" name="withdraw-type" value="outTrans">
                    <div class="outTrans methods pointer bg-blue-tri cw ph10 pv4 round2x"  id="outTrans" @click="activeMethod('outTrans')">
                        <div class="fs14 p5 oh" @click="getExpenseTransactions">{{store.state.locales['expense']}}</div>
                    </div>
                </label>
            </span>
            
        </div>
        <!-- transactions table -->
        <div class="round2x mt15 bgw p15 oxa">
            <tbl_nm style="min-width: 770px">
                <rw class="b" style="height: 30px">
                    <!-- <cl class="bb pb5 vm"><input type="checkbox" class="vm" id="Main-Checkbox"></cl> -->
                    <cl class="bb pb5 vm">{{store.state.locales['id']}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{store.state.locales['date']}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{store.state.locales['order_number']}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{store.state.locales['order_status']}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{store.state.locales['payment_method']}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{store.state.locales['type']}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{store.state.locales['amount']}}</cl>
                </rw>

                <rw :class="`${t.Type}`" style="height: 50px " v-for="(t,index) in arrayTransactions" v-bind:key="index">
                    <!-- <cl class="bb pb5 vm"><input type="checkbox" class="vm"></cl> -->
                    <cl class="bb pb5 vm">{{t.objectId}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{new Date(t.createdAt).toLocaleDateString()}} <span class="cb50">{{new Date(t.createdAt).toLocaleTimeString()}}</span></cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{t.orderNumber}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm ">{{t.orderStatus}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{t.paymentMethod}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class="bb pb5 vm">{{t.Type}}</cl>
                    <cl class="bb colSep"></cl>
                    <cl class=" bb pb5 vm amount b">{{t.amountSymbol}}{{t.Amount}}</cl>
                </rw>
            </tbl_nm>
        </div>
    </div>
</template>
<script>
import { onMounted,ref} from "vue";
import store from '@/store'
export default {
    setup(){
        let arrayTransactions=ref([])
        onMounted(()=>{
            getAllTransactions()
        })
        // function CheckAllTrans(){
        //     let AllTrans=document.querySelectorAll("[trans]")
        //     let MainCheckbox= document.getElementById("Main-Checkbox")
        //     if(MainCheckbox.checked)
        //         AllTrans.forEach(element => element.checked = true);
        //     else
        //         AllTrans.forEach(element => element.checked = false);
        // }
        function handleAddMoney(){
            store.state.globalData.callPopup="Popup"
            store.state.globalData.formsComptName="WalletRefill"
        }
        
        function activeMethod(id) {
            let AllTrans=document.querySelectorAll("[trans]")
            // let MainCheckbox= document.getElementById("Main-Checkbox")
            AllTrans.forEach(element => element.checked = false);
            // MainCheckbox.checked=false;
            let x = document.getElementById(id);
            let divs=document.querySelectorAll('.methods');
            divs.forEach(element => {
                element.classList.remove('active')
            });
            x.classList.add("active");
        }

        function getAllTransactions(){
            store.methods.getData(
            {"fields" :"orderNumber,orderStatus,paymentMethod,Type,Amount"},
            'Transactions?',function(response,success){
                if(success){
                    for(let i=0;i<response.results.length;i++){
                        if(response.results[i].Type==='refill')
                            response.results[i].amountSymbol = "+"
                        else {
                            response.results[i].amountSymbol = "-" 
                        }
                    }
                    arrayTransactions.value=response.results
                }else{
                    alert("Something goes wrong plz try again")
                }
            })
        }

        function getIncomeTransactions(){
            store.methods.getData(
            {"fields" :"orderNumber,orderStatus,paymentMethod,Type,Amount",
            "where": {
                "type":"refill"
            },
            },
            'Transactions?',function(response,success){
                if(success){
                    for(let i=0;i<response.results.length;i++){
                            response.results[i].amountSymbol = "+"
                    }
                    arrayTransactions.value=response.results
                }else{
                    alert("Something goes wrong plz try again")
                }
            })
        }

        function getExpenseTransactions(){
            store.methods.getData(
            {"fields" :"orderNumber,orderStatus,paymentMethod,Type,Amount",
            "where": {
                "type":"charged"
            },
            },
            'Transactions?',function(response,success){
                if(success){
                    for(let i=0;i<response.results.length;i++){
                        response.results[i].amountSymbol = "-"
                    }
                    arrayTransactions.value=response.results

                }else{
                    alert("Something goes wrong plz try again")
                }
            })
        }

    return {
        activeMethod,
        getAllTransactions,
        arrayTransactions,
        getIncomeTransactions,
        getExpenseTransactions,
        handleAddMoney,
        store
        }
    }
}
</script>
<style scoped> 
    .methods.active{
        background-color: #197FAF;
        color:#FFFFFF
    }
    .refill .amount {
        color:green;
    }
</style>