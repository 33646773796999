<template>
  <div class="about pl250 l" :style="'padding-'+`${store.state.locales['dir_left']}`+':250px'">
    <SideMenu />
    <TopNavbar style="" />
    <div class="l mh100" style="background-color:#e8ecff">
      <component v-bind:is="store.state.globalData.componentName"></component>
    </div>
    <component
      style="z-index: 1000;border-radius:16px;"
      v-bind:is="store.state.globalData.callPopup"
    ></component>
  </div>
</template>

<script>
// @ is an alias to /src
import Popup from "@/components/Popup.vue";
import SideMenu from "@/components/SideMenu.vue";
import TopNavbar from "@/components/TopNavbar.vue";
import Credit from "@/views/dashboard/Credit.vue";
import Settings from "@/views/dashboard/Settings.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import store from "@/store";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";

export default {
  name: "Main",
  components: {
    SideMenu,
    Dashboard,
    Credit,
    Settings,
    TopNavbar,
    Popup,
  },
  watch: {
    $route(to, from) {
      let nodes = to.fullPath
        .replace(new RegExp("^[" + "/" + "]+"), "")
        .split("/");
      if (!nodes[0] || (nodes[0] && (!nodes[1] || (!Object.prototype.hasOwnProperty.call(store.state.globalData.user[0].privileges,nodes[1]))))) {
        if(!nodes[0]) nodes[0] = "dashboard";
        nodes[1] = store.state.globalData.user[0].privilegeZero;
        router.push({ name: "Main", params: { n1: nodes[0], n2: nodes[1],n3:nodes[2] } });
      }

      store.state.globalData.componentName = nodes[0];
      store.methods.updateNodes(nodes);
    },
  },
  setup() {
    const route = useRoute();
    (window.onscroll = function() {
      var sT = window.scrollY;
      var kuku = document.getElementById("top-navbar");
      if (sT >= 20) {
        kuku.classList.add("light");
      } else {
        kuku.classList.remove("light");
      }
    }),
      onMounted(() => {
        console.log("state from main ", store.state.globalData);
    
        let nodes = route.fullPath
          .replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");
          
          if(nodes.length==1&&nodes[0]=="") nodes = document.location.pathname.replace(new RegExp("^[" + "/" + "]+"), "")
          .split("/");
      if (!nodes[0] || (nodes[0] && (!nodes[1] || (!Object.prototype.hasOwnProperty.call(store.state.globalData.user[0].privileges,nodes[1]))))) {
        if(!nodes[0]) nodes[0] = "dashboard";
          nodes[1] = store.state.globalData.user[0].privilegeZero;
            router.push({ name: "Main", params: { n1: nodes[0], n2: nodes[1],n3:nodes[2]===undefined?'':nodes[2] } });
        } else {
          store.state.globalData.componentName = nodes[0];
          store.methods.updateNodes(nodes);
        }
      });
    return {
      store,
    };
  },
};
</script>
<style></style>
