<template>
<div popup="" class="c ov pf" >
        <div class="tbl_nm fixed" style="height:100%;">
            <div class="col_nm vt popCl">
                <div class="pop l is-visible" style="border-radius:16px;">
                    <div class="pop_h">
                        <div class="pop_tit">Transactions</div>
                        <div class="pop_close" @click="handleClosePopup"><i class="mdi mdi-close"></i></div>
                    </div>
                    <div class="l  fs16" style="padding:19px 24px;">
                        <component v-bind:is="store.state.globalData.formsComptName"></component>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>

import store from '@/store'
import WalletRefill from "@/components/forms/WalletRefill.vue";
export default {
    components: {
        WalletRefill
    },

    setup() {
        function handleClosePopup(){
          store.state.globalData.callPopup="" 
          store.state.globalData.componentName="Credit"
        }
        return{
            handleClosePopup,
            store
        }
        
    },
}
</script>
