<template>
    <div class="pb20">
        <div class="pt50 cw ph25 con" style="background-color:#197FAF;   height:300px;  " :style="{ backgroundPosition:`${store.state.locales['dir_right']}`+' -20px !important',backgroundImage:`url(${imagebankurl})`}">
            <div class="mt20 fs24 b">{{store.state.locales['dashboard']}}</div> 
            <div class="fs14">{{store.state.locales['see_your_up_to_date_data']}}</div>
            <div v-if="showAllResto" class="g_nm l cw lh2 pv5 ph10 cb vm restoDropdown restaurantDropMenu fs14 trilivery-navbar pointer" style="border-radius: 0.75rem 0.75rem 0 0" @click="openMenu">
                <span class="restaurantDropMenu" :style="[optSelect ? {'color': 'black','border-radius': '0.75rem 0.75rem 0 0'} : {'border-radius': '0.75rem 0.75rem 0 0'}]">{{singleResto}}</span>                
                <span class="restaurantDropMenu ml8 mdi restoMenuArrow mdi-chevron-down" :style="[optSelect ? {'color': 'black','border-radius': '0.75rem 0.75rem 0 0'} : {'border-radius': '0.75rem 0.75rem 0 0'}]"></span>
                <div v-if="optSelect" class="cb sh ov ba pb5 bgw oya " style="z-index: 999; top:100%; max-height: 200px; border-radius: 0 0 .75rem .75rem;">
                    <a class="menuHref" :href="'/'+`${nodes[0]}`+'/'+`${nodes[1]}`">
                        <div class="fs14 lh14 pl8 pv8 optionMenu oe">All</div>
                    </a>
                    <div class="fs14 lh14 pl8 pv8 optionMenu oe"  v-for="(r, i) in restaurant" :key="i">
                        <a class="menuHref" :href="'/'+`${nodes[0]}`+'/'+`${nodes[1]}`+'/'+`${r.Restaurant}`">
                            <div>
                                {{r.Restaurant}}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="ph25 mt20" style="margin-top:-100px;">
            <div class="fs0">
                <div class="g_nm w25 md_w33 m_m_w100 pr15 m_m_pl15 pv15">
                    <div class="p20 c round2x sh" style="background-color:#EEFEFD;">
                        <!--  -->
                        <div class="b fs24 b">{{ordersNum}}</div>
                        <div class="mt4 fs18 b nw oe">Orders</div>
                        <div class="mt4 fs12 lh16 cb50 oh " style="height:32px">Number of placements</div>
                    </div>
                </div>
                <div class="g_nm w25 md_w33 m_m_w100 ph15 m_m_ph15 pv15">
                    <div class="p20 c round2x sh" style="background-color:#FEEEEF;">
                        <!--  -->
                        <div class="b fs24 b">{{engagement}}</div>
                        <div class="mt4 fs18 b nw oe">Engagement</div>
                        <div class="mt4 fs12 lh16 cb50 oh " style="height:32px">How viewers interact with your products</div>
                    </div>
                </div>
                <div class="g_nm w25 md_w33 m_m_w100 ph15 m_m_ph15 pv15">
                    <div class="p20 c round2x sh" style="background-color:#F2E9FF;">
                        <!--  -->
                        <div class="b fs24 b">{{clicks}}</div>
                        <div class="mt4 fs18 b nw oe">Clicks</div>
                        <div class="mt4 fs12 lh16 cb50 oh " style="height:32px">User clicks on your products</div>
                    </div>
                </div>
                <div class="g_nm w25 md_w33 m_m_w100 pl15 md_pl0  md_pr15 m_m_ph15 pv15">
                    <div class="p20 c round2x sh" style="background-color:#FBF0EF;">
                        <!--  -->
                        <div class="b fs24 b">{{views}}</div>
                        <div class="mt4 fs18 b nw oe">Views</div>
                        <div class="mt4 fs12 lh16 cb50 oh " style="height:32px">Impression on products</div>
                    </div>
                </div>
            </div>
            <tbl>
                <cl class="w70 vt">
                    <div class="round4x bgw sh ph15 pb10 pt15 mt15">
                        <div class="fs0">
                            <div class="g_nm w75">
                                <div class="g_nm mt5 mr10 b fs16">{{store.state.locales['sales_analytics']}}</div>
                                <div class="g_nm ">
                                    <div class="g_nm  round4x fs14 mt5 mr5 pv5 ph10 brdr">
                                        <span class="g_nm round mr5" style="width: 10px; height: 10px; background: #15d395;"></span>
                                        <span>{{store.state.locales['income']}}</span>
                                    </div>
                                    <div class="g_nm  round4x fs14 mt5 pv5 ph10  brdr">
                                        <span class="g_nm round mr5" style="width: 10px; height: 10px; background: #138dff;"></span>
                                        <span>{{store.state.locales['expense']}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="ov fh la round4x mt5 pv5 ph10 brdr fs14" style="height: 30px">
                                <span>{{store.state.locales['week']}}</span>
                                <span class="mdi mdi-chevron-down"></span>
                            </div>
                        </div>
                        <div>
                            <div class="mb30">
                                <div class="g_nm w5 fs10 cb40 b">8k</div>
                                <div class="g_nm w90" style="height: 1px; background: #bcc1c9;"></div>
                            </div>
                            <div class="mb30">
                                <div class="g_nm w5 fs10 cb40 b">6k</div>
                                <div class="g_nm w90" style="height: 1px; background: #bcc1c9;"></div>
                            </div>
                            <div class="mb30">
                                <div class="g_nm w5 fs10 cb40 b">4k</div>
                                <div class="g_nm w90" style="height: 1px; background: #bcc1c9;"></div>
                            </div>
                            <div class="mb30">
                                <div class="g_nm w5 fs10 cb40 b">2k</div>
                                <div class="g_nm w90" style="height: 1px; background: #bcc1c9;"></div>
                            </div>
                            <div class="mb10">
                                <div class="g_nm w5 fs10 cb40 b">0</div>
                                <div class="g_nm w90" style="height: 1px; background: #bcc1c9;"></div>
                            </div>
                            <div class="mb10 fs0 ">
                                <div class="g_nm w15 fs12 b cb40"></div>
                                <div class="g_nm w15 fs12 b cb40">18 Feb</div>
                                <div class="g_nm w15 fs12 b cb40">19 Feb</div>
                                <div class="g_nm w15 fs12 b cb40">20 Feb</div>
                                <div class="g_nm w15 fs12 b cb40">21 Feb</div>
                                <div class="g_nm w15 fs12 b cb40">22 Feb</div>
                            </div> 
                            <div class="ov" style="top: 4%; ">
                                <div class="mb25 w15 g_nm w15 ">
                                   
                                </div>
                                <!-- to get exact height *23.75 -->
                                <div class="mb25 w15 g_nm w15">
                                    <div class="g_nm mr2" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 95px; background: #15d395;"></div>
                                    <div class="g_nm" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 142.5px; background: #138dff;"></div>
                                </div>
                                <div class="mb25 w15 g_nm w15">
                                    <div class="g_nm mr2" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 190px; background: #15d395;"></div>
                                    <div class="g_nm" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 118.7px; background: #138dff;"></div>
                                </div>
                                <div class="mb25 w15 g_nm w15">
                                    <div class="g_nm mr2" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 166.2px; background: #15d395;"></div>
                                    <div class="g_nm" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 85px; background: #138dff;"></div>
                                </div>
                                <div class="mb25 w15 g_nm w15">
                                    <div class="g_nm mr2" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 150px; background: #15d395;"></div>
                                    <div class="g_nm" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 130px; background: #138dff;"></div>
                                </div>
                                <div class="mb25 w15 g_nm w15">
                                    <div class="g_nm mr2" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 120px; background: #15d395;"></div>
                                    <div class="g_nm" style="border-radius: 5px  5px 0 0 ;width: 15px; height: 150px; background: #138dff;"></div>
                                </div>
                                

                            </div>    
                        </div>
                    </div>
                </cl>
                <cl class="colSep20"></cl>
                <cl class="w30 vt c">
                    <div class=" round4x bgw sh p15 mt15" style="min-width:200px;">
                        <div><span class="mdi mdi-wallet-outline c-blue-tri fs20 mr5 vm "></span> <span v-html="store.state.locales['your_current_balance']"></span></div>
                        <div class="mt20 fs30 b">${{store.state.globalData.user[0].Balence}}</div>
                        <div class="mt15 fs12 cb50">
                            {{store.state.locales['check_your_balance']}}
                        </div>
                        <router-link :to="'/credit/'+`${nodes[1]}`">
                        <div class="mt10 pointer round3x bg-blue-tri cw ph15 lh40  b m_w50 m_m_w100 g_nm">{{store.state.locales['balance_sheet']}}</div>
                        </router-link>
                    </div>
                </cl>
            </tbl>
            <div class="round4x brdr sh p25 mt15 bgw">
                <div class="mb15">
                    <span class="fs16 b">{{store.state.locales['transactions']}}</span>
                    <router-link :to="'/credit/'+`${nodes[1]}`"><span class="ov la blue">{{store.state.locales['see_all']}}</span></router-link>
                </div>
                <div class="oxa">
                    <tbl_nm >
                        <rw style="height: 40px;">                                      
                            <cl class="b pl15" style="min-width: 40px; color: #c4c8d3;background: #f9f9fb; border-radius: 10px 0 0 10px">{{store.state.locales['id']}}</cl>
                            <cl class="b" style="min-width: 90px; color: #c4c8d3;background: #f9f9fb;">{{store.state.locales['date']}}</cl>
                            <cl class="b" style="min-width: 90px; color: #c4c8d3;background: #f9f9fb;">{{store.state.locales['time']}}</cl>
                            <cl class="b" style="min-width: 100px; color: #c4c8d3;background: #f9f9fb;">{{store.state.locales['order_number']}}</cl>
                            <cl class="b" style="min-width: 100px; color: #c4c8d3;background: #f9f9fb;">{{store.state.locales['order_status']}}</cl>
                            <cl class="b" style="min-width: 90px; color: #c4c8d3;background: #f9f9fb;">{{store.state.locales['payment_method']}}</cl>
                            <cl class="b" style="min-width: 90px; color: #c4c8d3;background: #f9f9fb;">{{store.state.locales['type']}}</cl>
                            <cl class="b" style="color: #c4c8d3;background: #f9f9fb; border-radius: 0 10px 10px 0">{{store.state.locales['amount']}}</cl>
                        </rw>
                        <rw :class="`${t.Type}`" style="height: 55px;"  v-for="(t,index) in arrayTransactions" v-bind:key="index">
                            <cl class="bb pl15">{{t.objectId}}</cl>
                            <cl class="bb">{{new Date(t.createdAt).toLocaleDateString()}}</cl>
                            <cl class="bb">{{new Date(t.createdAt).toLocaleTimeString()}}</cl>
                            <cl class="bb">{{t.orderNumber}}</cl>
                            <cl class="bb"><span v-if=(t.orderStatus) class="pv5 ph10 round2x" style="color: #5dd3ae; background: #e4faf2;">{{t.orderStatus}}</span></cl>
                            <cl class="bb">{{t.paymentMethod}}</cl>
                            <cl class="bb">{{t.Type}}</cl>
                            <cl class="bb b amount" >{{t.amountSymbol}}{{t.Amount}}</cl>
                        </rw>
                    </tbl_nm >
                </div>
                
            </div>

        </div>
    </div>
</template>
<script>
import { onMounted,onUnmounted,ref} from "vue";
import imagebankurl from '@/assets/bank.svg';
import store from '@/store'
import { useRoute } from "vue-router";
export default {
    created() {
    // document.addEventListener("click", function(e){
    //   console.log(e.target.classList.value); 
    // });

        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)){
                this.dropDownVisibility = false
            }
            if(!e.target.classList.value.includes("restaurantDropMenu")){
                this.optSelect = false
                store.methods.editClasses('.restoDropdown', 'remove', 'bg-gray')
                store.methods.editClasses('.restoDropdown', 'remove', 'sh')
                store.methods.editClasses('.restoMenuArrow', 'remove', 'mdi-chevron-up')
            }
        })
    },

    watch: {
    $route(to, from) {
      this.nodes = to.fullPath
        .replace(new RegExp("^[" + "/" + "]+"), "")
        .split("/");
       
      //  if(Object.keys(this.store.state.globalData.user[0].privileges).length!=0) 
    //   this.oneStore=this.store.state.globalData.user[0].privileges[this.nodes[1]]._Provider.results[0].Name


    },
  },


    setup(){
        const route = useRoute();
        let arrayTransactions=ref([])
        let amountSymbol=ref("")
        let nodes=ref([])
        let showAllResto=ref(true)
        let optSelect = ref(false)
        let restaurant = ref([])

        let singleResto = ref("All")

        let views = ref(0)
        let clicks = ref(0)
        let engagement = ref(0)
        let ordersNum = ref(0)
        function view_click_Counter(data){
            // console.log("data: ",data)
            clicks.value = 0
            views.value = 0
            for (let i = 0; i < data.length; i++) {
                let a = data[i].Data
                let x = a.split(',')
                let y = []
                for (let i = 0; i < x.length; i++) {
                    y.push(x[i].split(':'))    
                }
                for (let i = 0; i < y.length; i++) {
                    if(y[i][1] == 0)
                        clicks.value++
                    else if(y[i][1] == 1)
                        views.value++
                }
            }
            console.log("Views: ",views.value,"\nClicks: ",clicks.value)
            if(clicks.value == 0)
            engagement.value = 1/views.value
            else if(views.value == 0)
            engagement.value = clicks.value/1
            else
            engagement.value = (clicks.value/views.value).toFixed(1) 
            
        }

        // function test(itemstatsId){
        //     console.log("itemstatsId ",itemstatsId)
        //     console.log("Data",restaurant.value[itemstatsId].Data)
        // }
        function openMenu(){
            optSelect.value = !optSelect.value
            
            store.methods.editClasses('.restoDropdown', 'toggle', 'sh')
            store.methods.editClasses('.restoDropdown', 'toggle', 'bg-gray')
            // store.methods.editClasses('.dropdown', 'toggle', 'cw')
            store.methods.editClasses('.restoMenuArrow', 'toggle', 'mdi-chevron-up')
        }

        function getLastTransactions(){
        store.methods.getData(
            {"fields" :"orderNumber,orderStatus,paymentMethod,Type,Amount",
            'limit':'5'
            },
            'Transactions?',function(response,success){
                if(success){
                    for(let i=0;i<response.results.length;i++){
                        if(response.results[i].Type==='refill')
                            response.results[i].amountSymbol = "+"
                        else {
                            response.results[i].amountSymbol = "-" 
                        }
                    }
                    arrayTransactions.value=response.results
                }else{
                    alert("Something goes wrong plz try again")
                }
            })
        }
        function getOrdersNumber(){
            ordersNum.value = 0
            if(nodes.value[2] === undefined || nodes.value[2]===''){
                store.methods.getData(
                    {"fields" : "Total,Restaurant,Provider",
                    "where" : {
                        "User" : store.state.globalData.user[0].objectId,
                        "createdAt":{
                            "month":new Date().getMonth()+1
                        },
                        "Provider": store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].objectId
                    }
                    },
                    'Orders?',function(response,success){
                        if(success){
                            for (let i = 0; i < response.results.length; i++) {
                                ordersNum.value += response.results[i].Total
                                console.log("inside for orderNum is: ",ordersNum.value)
                            }
                        }else{
                            alert("Something goes wrong plz try again")
                        }
                    })
            }
            else{
                store.methods.getData(
                    {"fields" : "Total,Restaurant,Provider",
                    "where" : {
                        "User" : store.state.globalData.user[0].objectId,
                        "createdAt":{
                            "month":new Date().getMonth()+1
                        },
                        "Provider": store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].objectId,
                        "Restaurant": nodes.value[2]
                    }
                    },
                    'Orders?',function(response,success){
                        if(success){
                            for (let i = 0; i < response.results.length; i++) {
                                ordersNum.value += response.results[i].Total
                                console.log("inside for orderNum is: ",ordersNum.value)
                            }
                        }else{
                            alert("Something goes wrong plz try again")
                        }
                    })
            }
        }
        onMounted(()=>{
            nodes.value = route.fullPath
            .replace(new RegExp("^[" + "/" + "]+"), "")
            .split("/");
            if(nodes.value.length==1&&nodes.value[0]=="") nodes.value = document.location.pathname.replace(new RegExp("^[" + "/" + "]+"), "").split("/");
            if(store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].Type==='Restaurant'){
                showAllResto.value=false
            }
            
            let TopNav=document.getElementById("top-navbar");
            TopNav.classList.add("dark")
            getLastTransactions()
            getAllItemStats()
            getOrdersNumber()

             
       })
        onUnmounted(()=>{
            let TopNav=document.getElementById("top-navbar");
            TopNav.classList.remove("dark")
        })

        function getAllItemStats(){
            
            store.methods.getData(
            {"fields" :"Restaurant,Item,Data",
                "where":{
                    "Vender":store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].objectId,
                    "createdAt":{
                        "month":new Date().getMonth()+1
                    }
              },
               "group":"Restaurant",
               "limit":-1
            },
            'ItemStats?',function(response,success){
                if(success){
                   restaurant.value=response.results
                   console.log("test ",restaurant.value)
                   getData()                 
                
                }else{
                    alert("Something goes wrong plz try again")
                }
            })
        }

    function getData(){
        if(nodes.value[2] === undefined || nodes.value[2]===''){
            store.methods.getData(
                {"fields" :"Restaurant,Item,Data",
                    "where":{
                        "Vender":store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].objectId,
                        "createdAt":{
                        "month":new Date().getMonth()+1
                    }
                }
                },
                'ItemStats?',function(response,success){
                    if(success){
    
                    console.log("data resto ",response.results)
                    view_click_Counter(response.results)
                    console.log("single Item 1 \n Views: ",views.value,"\nClicks: ",clicks.value)
                    singleResto.value = "All"

                    }else{
                        alert("Something goes wrong plz try again")
                    }
            })
        }
        else{
            store.methods.getData(
                {"fields" :"Restaurant,Item,Data",
                    "where":{
                        "Vender":store.state.globalData.user[0].privileges[nodes.value[1]]._Provider.results[0].objectId,
                        "Restaurant":nodes.value[2],
                        "createdAt":{
                        "month":new Date().getMonth()+1
                    }
                   }
                },
                'ItemStats?',function(response,success){
                    if(success){
    
                    console.log("data resto ",response.results)
                    view_click_Counter(response.results)
                    console.log("single Item 2 \n Views: ",views.value,"\nClicks: ",clicks.value)
                    singleResto.value = nodes.value[2]

                    }else{
                        alert("Something goes wrong plz try again")
                    }
            })
        }
        

    }





        return{
            imagebankurl,
            getLastTransactions,
            arrayTransactions,
            amountSymbol,
            store,
            nodes,
            getAllItemStats,
            optSelect,
            openMenu,
            showAllResto,
            restaurant,
            view_click_Counter,
            views,
            clicks,
            engagement,
            ordersNum,
            getOrdersNumber,
            singleResto,
            getData

            }
    }
    
}
</script>
<style >
    .refill .amount {
        color:green;
    }
    /* unvisited link */
    .menuHref:link {
    color: black;
    }

    /* visited link */
    .menuHref:visited {
    color: black;
    }

    /* mouse over link */
    .menuHref:hover {
    color: black;
    }

    /* selected link */
    .menuHref:active {
    color: black;
    }
</style>