<template>
  <div
    class="ov pf normal ba pl250" style="z-index: 900;height:50px; " id="top-navbar" :style="'padding-'+`${store.state.locales['dir_left']}`+':250px'">
    <div class="lh50 ph25">
      <div class="g_nm vt pr10 vm h m_sib" >
        <div class="cb90 btn pointer nw lh32 ">
          <span class="fs22 vm mdi mdi-menu" @click="handleOpenSideMenu"></span>
        </div>
      </div>
      <!-- <div class="g_nm vm fs24 b trilivery-navbar">Trilivery</div> -->
      <div class="proDropMenu g_nm l cw lh2 pv5 ph10 cb vm dropdown  fs14 trilivery-navbar pointer" style="border-radius: 0.75rem 0.75rem 0 0" @click="openMenu">
        <span class="proDropMenu" :style="[optSelect ? {'color': 'black','border-radius': '0.75rem 0.75rem 0 0'} : {'border-radius': '0.75rem 0.75rem 0 0'}]">{{oneStore}}</span>
        <span class="proDropMenu ml8 mdi menuArrow mdi-chevron-down" :style="[optSelect ? {'color': 'black','border-radius': '0.75rem 0.75rem 0 0'} : {'border-radius': '0.75rem 0.75rem 0 0'}]"></span>
        <div v-if="optSelect" class="cb sh ov ba pb5 bgw oya " style="top:100%; max-height: 200px; border-radius: 0 0 .75rem .75rem;">
          <div class="fs14 lh14 pl8 pv8 optionMenu oe"  v-for="(store, i) in stores" :key="i">
            <a  :href="'/'+`${nodes[0]}`+'/'+`${store.objectId}`+'/'+`${n2}`"><p class="cb oe">{{store._Provider.results[0].Name}}</p></a>
          </div>
        </div>
      </div>
    </div>
    <div class="la ov fs16 r mt10" :style="`${store.state.locales['dir_right']}`+':25px'">
      <span class="mdi mdi-bell-outline"></span>
      <!-- <a href="/logout"> -->
      <router-link to="/logout">
        <span class="mdi mdi-logout ml10 pointer" ></span>
      </router-link>
      <!-- </a> -->
      <span>
          <button href="#" class="cw b0 pointer"
          @click="toggleDropDownVisibility"
          @keydown.space.exact.prevent="toggleDropDownVisibility"
          style="height:25px;background: transparent;">
          <span :class="`flag flag-${flag} fs16 ml10 round100`" ></span>
        </button>
        <transition name="dropdown-fade" style="z-index:100;">
          <ul class="sh10 bgw round2x p5 pa  c"  v-if="dropDownVisibility"  style="list-style-type: none;z-index: 30; top:100%; min-width:110px;" :style="`${store.state.locales['dir_right']}`+':00px'">
            <li class="ph5  pointer" @click.prevent="switchLanguage('en')"  @keydown.space.exact.prevent="toggleDropDownVisibility">
                <span class="flag flag-gb fs16 "></span>
                <span class="ml2 cb fs14" style="color:#000000 !important;">English</span>
            </li>
            <li class="pointer ph5"  @click.prevent="switchLanguage('ar')"  @keydown.space.exact.prevent="toggleDropDownVisibility">
                <span class="flag flag-sa fs16 "></span>
                <span class="ml2 vt cb fs14" style="color:#000000 !important;">Arabic</span>
            </li>
          </ul>
        </transition>
      </span>
    </div>
  </div>
</template>
<script>
// import router from "@/router";
import store from '@/store'
import { useI18n } from "vue3-i18n";
import { ref,onMounted } from 'vue'
import { useRoute } from "vue-router";
export default {
  created() {


      window.addEventListener('click', (e) => {
        if (!this.$el.contains(e.target)){
          this.dropDownVisibility = false
        }
        if(!e.target.classList.value.includes("proDropMenu")){
          this.optSelect = false
          store.methods.editClasses('.dropdown', 'remove', 'bg-gray')
          store.methods.editClasses('.dropdown', 'remove', 'sh')
          store.methods.editClasses('.menuArrow', 'remove', 'mdi-chevron-up')
        }
      })
  },

    watch: {
    $route(to, from) {
      this.nodes = to.fullPath
        .replace(new RegExp("^[" + "/" + "]+"), "")
        .split("/");
      //  if(Object.keys(this.store.state.globalData.user[0].privileges).length!=0) 
      this.oneStore=this.store.state.globalData.user[0].privileges[this.nodes[1]]._Provider.results[0].Name
      if(this.nodes[2]==='general' || this.nodes[2]==='profile'){
        this.n2=this.nodes[2]
      }else{
        this.n2=''
      }

    },
  },
  setup() {
    let n2=ref("")
    let nodes=ref([])
    const route = useRoute();
    const i18n = useI18n();
    let dropDownVisibility=ref(false)
    let flag=ref('gb')
    // let stores = ["ali", "samir"]
    let stores = store.state.globalData.user[0].privileges
    let oneStore = ref("Stores")
    let optSelect = ref(false)



    function openMenu(){
      optSelect.value = !optSelect.value
      
      store.methods.editClasses('.dropdown', 'toggle', 'sh')
      store.methods.editClasses('.dropdown', 'toggle', 'bg-gray')
      // store.methods.editClasses('.dropdown', 'toggle', 'cw')
      store.methods.editClasses('.menuArrow', 'toggle', 'mdi-chevron-up')
    }


    onMounted(()=>{
    nodes.value = route.fullPath.replace(new RegExp("^[" + "/" + "]+"), "").split("/");  
    if(nodes.value.length==1&&nodes.value[0]=="") nodes.value = document.location.pathname.replace(new RegExp("^[" + "/" + "]+"), "").split("/");
      flag.value=(store.methods.BGC("LOCAL")=='ar') ? "sa" : "gb";
      oneStore.value=store.state.globalData.user[0].privileges[store.state.globalData.user[0].privilegeZero]._Provider.results[0].Name
      if(nodes.value[2]==='general' || nodes.value[2]==='profile'){
        n2.value=nodes.value[2]
      }else{
        n2.value=''
      }
    
    })

    function handleOpenSideMenu(){
            let menu=document.getElementById("Side-Menu");
            if(menu.classList.contains("m_h")){
                menu.classList.remove("m_h")
            }
            else{
                menu.classList.toggle("h");
            }
        }

    function toggleDropDownVisibility(){
       dropDownVisibility.value=!dropDownVisibility.value
    }
    
    function switchLanguage(locale) {
      if(locale==="ar"){
        flag.value="sa"
      }else{
       flag.value="gb"
      }
      i18n.setLocale(locale);
      store.methods.BSC("LOCAL",locale)
      store.state.globalData.settings.language=locale
      location.reload()
    }
    
    return{
      handleOpenSideMenu,
      switchLanguage,
      store,
      dropDownVisibility,
      flag,
      toggleDropDownVisibility,
      stores,
      openMenu,
      optSelect,
      oneStore,
      nodes,
      n2
    }
  }
}
</script>
<style >
  .optionMenu:hover{
    background:  #c3ecff;
  }
  .bg-gray{
    background: #dcdcdc;
  }


</style>