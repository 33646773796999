<template>
    <div>
        <form name="myForm"  @submit.prevent="handleRefill">
            Amount: <input type="text" name="amount" v-model='amount'>
            <button type="submit" class="bg-oil b0 brdr w100 pointer pv10 fs18 start cw c">Refill</button>
         </form>
    </div>
</template>
<script>
import { ref } from 'vue'
import store from '@/store'
export default {
    setup() {
        let amount=ref("")
        function handleRefill(){
            store.methods.postData('Transactions/',{
            "Amount":amount.value,
            "Type":"refill"
             },function(response,success){
                if(success){
                  alert("success")
                  console.log(response)
                }else{
                  alert("Something goes wrong plz try again")
                    }
                  })
        }
        return{
            amount,
            handleRefill
        }
        
    },
}
</script>

