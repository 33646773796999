import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import axios from 'axios'

axios.defaults.baseURL = 'https://api.backendadmin.com/1/'
axios.defaults.headers = {
    'X-BEA-Application-Id': 'Uy-fyqdkWEW6TLVBn1FwZsYOUXyPRcQhMw4t2JPN910',
    'X-BEA-Authorization': 'ph890aR8xPmYDeDcA65iEKvO-KT5ufIwEwlinPFgAis',
    'Content-Type': 'application/json',
    // 'Accept-Language': lang
}

createApp(App).use(router).use(i18n).mount('#app')
